import Block2Columns from "../../components/Block2ColumnsText";
import DataFetcherTemplate from "../../hooks/fetchTemplate";
import BannerGeneral from "../../components/BannerGeneral";
import BlockImageCabecera from "../../components/BlockImageCabecera";
import Block4ColumnsText from "../../components/Block4ColumnsText";
import Block3Columns from "../../components/Block3Columns";
import BlockRightImage from "../../components/BlockRightImage";

import ImageGallery from "../../components/ImageGallery";
import ModalFixed from "../../components/ModalFixed";
import InstagramEmbed from "../../components/InstagramEmbed";

function Internacional() {
  const DataDisplay = ({ data }) => (
    <>
      {data.map((item, index) => (
        <>
          <BlockImageCabecera
            src={item.field_portada}
            title={
              <>
                {" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.field_texto_portada,
                  }}
                />
              </>
            }
          ></BlockImageCabecera>
          <div className="graybg">
            <BlockRightImage
              src={item.field_imagen10}
              content={
                <>
                  {" "}
                  <p className="iconsize20">🌍🚀 </p>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque10,
                    }}
                  />{" "}
                </>
              }
            ></BlockRightImage>
          </div>
          <div className="margin" id="movilidad">
            <div className="relative ">
              <div className="layout8">
                <center>
                  <p className="iconsize20">✈️ </p>
                  <div
                    className="width700"
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque6,
                    }}
                  />
                </center>
              </div>
              <center>
                <div className="icon70 padding">
                  <Block2Columns
                    col1={
                      <>
                        <img src={item.field_imagen7} alt="utpl"></img>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque7,
                          }}
                        />
                      </>
                    }
                    col2={
                      <>
                        <img src={item.field_imagen8} alt="utpl"></img>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque8,
                          }}
                        />
                      </>
                    }
                  ></Block2Columns>
                </div>
                <br></br>
              </center>

              <ModalFixed
                color="#673ab7"
                title="Conoce el testimonio de nuestros Jaguares Internacionales 🌍"
                content={
                  <>
                    <div className="width400">
                      <InstagramEmbed url="https://www.instagram.com/reel/DGiuA-Quaej/" />
                    </div>
                  </>
                }
              ></ModalFixed>
            </div>
          </div>
          <div className="relative ">
            <div className="align-center padding">
              <Block2Columns
                col2={
                  <ImageGallery gallery={item.field_gallery1}></ImageGallery>
                }
                col1={
                  <>
                    <center>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque9,
                        }}
                      />
                    </center>
                  </>
                }
              ></Block2Columns>
              <br></br>
            </div>

            <ModalFixed
              color="#246ec4"
              title="🚀 De la @UTPL a la @upna.nup : una experiencia que trasciende fronteras! 🌍✈️"
              content={
                <>
                  <div className="width400">
                    <InstagramEmbed url="https://www.instagram.com/reel/DEirPXJJ1X4" />
                  </div>
                </>
              }
            ></ModalFixed>
          </div>

          <div className="padding graybg">
            <center>
              <p className="iconsize20">📚💡</p>
              <div
                className="width700"
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque1,
                }}
              />

              <p>
                <b>📌 Estrategias clave para una educación sin límites:</b>
              </p>
            </center>
            <div className="margin icon70">
              <center>
                <Block4ColumnsText
                  col1={
                    <>
                      <img src={item.field_imagen2} alt="utpl"></img>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque2,
                        }}
                      />
                    </>
                  }
                  col2={
                    <>
                      <img src={item.field_imagen3} alt="utpl"></img>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque3,
                        }}
                      />
                    </>
                  }
                  col3={
                    <>
                      <img src={item.field_imagen4} alt="utpl"></img>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque4,
                        }}
                      />
                    </>
                  }
                  col4={
                    <>
                      <img src={item.field_imagen5} alt="utpl"></img>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque5,
                        }}
                      />
                    </>
                  }
                ></Block4ColumnsText>
              </center>
              <div className="margin">
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque11,
                  }}
                />{" "}
              </div>
            </div>
          </div>

          <div className="padding">
            <center>
              {" "}
              <p className="iconsize20"> 🌐🤝</p>
            </center>

            <div
              className="layout6"
              dangerouslySetInnerHTML={{
                __html: item.field_bloque12,
              }}
            />
            <br></br>
            <Block3Columns
              col1={
                <>
                  <img src={item.field_imagen13} alt="utpl"></img>
                  <br></br>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque13,
                    }}
                  />
                </>
              }
              col2={
                <>
                  <img src={item.field_imagen14} alt="utpl"></img>
                  <br></br>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque14,
                    }}
                  />
                </>
              }
              col3={
                <>
                  <img src={item.field_imagen15} alt="utpl"></img>
                  <br></br>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque15,
                    }}
                  />
                </>
              }
            ></Block3Columns>
          </div>
        </>
      ))}
    </>
  );

  const DataDisplay1 = ({ data }) => (
    <>
      {data.map((item, index) => (
        <>
          <div
            className="width700 layout8"
            dangerouslySetInnerHTML={{
              __html: item.field_bloque1,
            }}
          />
          <div className="padding2">
            <Block3Columns
              col1={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque2,
                    }}
                  />
                </>
              }
              col2={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque3,
                    }}
                  />
                </>
              }
              col3={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque4,
                    }}
                  />
                </>
              }
            ></Block3Columns>
          </div>

          <div
            className="width700 layout8"
            dangerouslySetInnerHTML={{
              __html: item.field_bloque5,
            }}
          />
          <div className="padding2">
            <Block3Columns
              col1={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque6,
                    }}
                  />
                </>
              }
              col2={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque7,
                    }}
                  />
                </>
              }
              col3={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque8
                    }}
                  />
                </>
              }
            ></Block3Columns>
             <Block3Columns
              col1={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque9
                    }}
                  />
                </>
              }
              col2={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque10,
                    }}
                  />
                </>
              }
              col3={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque11
                    }}
                  />
                </>
              }
            ></Block3Columns>
          </div>
        </>
      ))}
    </>
  );
  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-internacional.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>

      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-internacional2.json">
        {(data) => <DataDisplay1 data={data} />}
      </DataFetcherTemplate>

      <div className="padding" id="conecta">
        <center>
          <h2>
            <b>¿Te interesa</b> ser parte de nuestras iniciativas? 🌍{" "}
          </h2>

          <h2 className="tag3-retoma">
            Escríbenos a 📩 <b>utplglobalcampus@utpl.edu.ec</b>{" "}
          </h2>
        </center>
      </div>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Internacional;
